import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact" mdxType="SEO" />
    <h2>{`Contactez-nous`}</h2>
    <p>{`Avant de nous contacter, n'oubliez pas que nous avons une
`}<a parentName="p" {...{
        "href": "/aide"
      }}>{`page de questions-réponses`}</a>{`.`}</p>
    <p>{`Pour les demandes de renseignement, merci de nous contacter directement par
`}<a parentName="p" {...{
        "href": "mailto:info@lenfantdeau.fr"
      }}>{`email`}</a>{`. Nous n'utilisons pas la page facebook à cet effet.`}</p>
    <p>{`Vos questions sont traitées par les `}<strong parentName="p">{`parents bénévoles`}</strong>{` de l’association,
aussi nous vous remercions de votre patience.`}</p>
    <h3>{`Correspondance postale`}</h3>
    <p>{`Le courrier doit nous être transmis uniquement par voie postale. Nous ne serions
être tenus pour responsables, pas davantage que la Maison des Associations, de
courrier qui aurait été glissé sous la porte ou laissé à l’accueil.`}</p>
    <pre><code parentName="pre" {...{}}>{`L’Enfant d’Eau
MVAC Paris Centre
5bis, rue du Louvre
75001 Paris
`}</code></pre>
    <p>{`Cette adresse n'héberge que la boîte postale de l'association. Vous n'y
trouverez pas de permanence de l'association.`}</p>
    <h3>{`Le centre sportif`}</h3>
    <p>{`Vous pouvez rencontrer l’équipe de l’Enfant d’Eau au bassin Jean Dame (adresse
ci-dessous) aux heures d’ouverture de l’activité.`}</p>
    <pre><code parentName="pre" {...{}}>{`Centre sportif Jean Dame
17, rue Léopold Bellan
75002 Paris
`}</code></pre>
    <p>{`Merci de ne JAMAIS envoyer de courrier à cette adresse, qui est uniquement
l’adresse du centre où se déroule l’activité.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      